@import '@crystal-eyes/constants.scss';

.traitMeterCircle {
  width: 100%;
  :global(.circle) {
    position: absolute;
  }
}

.circlePopup {
  max-width: 14rem !important;
  background: white;
}
