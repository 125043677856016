.svg {
  fill: none;
  stroke: currentColor;

  &:global(.icon-Scale) {
    stroke-width: 1;
  }

  &:global(.icon-Disc) {
    stroke-width: 1px;
  }

  &:global(.icon-CheckmarkCircle) {
    circle {
      fill: #2ca7e4;
      stroke: none;
    }

    polygon {
      fill: white;
      stroke: none;
    }
  }
}
