@import '@crystal-eyes/constants.scss';
@import '@crystal-eyes/helpers.scss';

.profile {
  display: flex;
  flex-direction: row;
  padding: 16px;
  gap: 22px;
  background: white;
  border-radius: 8px;
  cursor: pointer;
  justify-content: center;
  height: calc(100% - 32px);

  .title {
    font-size: 1.5rem;
    line-height: 140%;
  }

  .profileImg {
    display: flex;
    align-items: center;
  }

  .infoContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;

    &.loading {
      width: 50%;
      gap: 10px;
    }
  }

  .job {
    font-size: 1.125rem;
    line-height: 140%;
    color: $gray-info;
    font-style: italic;
  }

  .header {
    color: black;
    font-size: 1.125rem;
    margin-bottom: 2px;
  }

  .rightColumn {
    justify-content: center;
    display: flex;
    flex-direction: column;
  }

  .compact {
    line-height: 1.1em;
  }
}

.subtitle {
  color: $gray-info;
  font-size: 1.1rem;
}

.discPhoto {
  display: flex;
  position: relative;
  overflow: hidden;
  flex-direction: column;
  align-items: center;

  .textPill {
    width: fit-content;
    text-align: center;
    background: $blue;
    padding: 6px 12px;
    border-radius: 100px;
    pointer-events: none;
    margin-top: -12px;
    z-index: 1;
    white-space: nowrap;
    font-size: 0.875rem;
    @extend .disc-color;
    @extend .disc-background-light-nt;
  }
}



.cropped {
  img {
    border-radius: 50%;
  }
}

.flex {
  display: flex !important;
}