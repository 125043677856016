@import '@crystal-eyes/constants.scss';

$line-color: #eee;

.traits {
  :global(.discLine) {
    width: 100%;
    display: grid;
    grid-template-columns: 6.5em 1fr 6.5em;
    justify-content: center;
    align-items: center;
    column-gap: 8px;

    :global(.word:first-child) {
      text-align: right;
    }
    :global(.word:last-child) {
      text-align: left;
    }

    :global(.line) {
      display: flex;
      align-items: center;
      justify-self: center;

      position: relative;
      width: calc(100% - 36px);
      height: 100%;
      box-sizing: border-box;

      :global(.lineBar) {
        height: 2px;
        width: 100%;
        background-color: $line-color;
      }

      :global(.tick) {
        position: absolute;
        width: 2px;
        height: 5px;
        top: calc(50% - 2.5px);
        bottom: calc(50% - 2.5px);
        background-color: #aeaeae;
      }

      & > :global(.tick:nth-child(1)) {
        left: 0;
      }
      & > :global(.tick:nth-child(2)) {
        left: calc(25% - 1px);
      }
      & > :global(.tick:nth-child(3)) {
        left: calc(50% - 1px);
      }
      & > :global(.tick:nth-child(4)) {
        left: calc(75% - 1px);
      }
      & > :global(.tick:nth-child(5)) {
        right: 0;
      }
    }

    :global(.lineIdentity) {
      width: 100%;
      position: absolute;
    }
  }
}
