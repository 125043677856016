@import '@crystal-eyes/constants.scss';

.bottomContainer {
  display: flex;
  flex-direction: column;
  gap: 16px;
  padding: 8px 24px 8px 0px;
  white-space: nowrap;

  .profile {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 24px;
    padding-left: 0px;

    .profileLink {
      text-wrap: wrap;
    }
  }

  .centered {
    display: flex;
    justify-content: start;
  }

  .tcContainer {
    &:hover {
      text-decoration: none;
    }
  }

  .logoutLink {
    display: flex;
    align-items: center;
    padding-bottom: 46px;
    padding-left: 8px;
    gap: 36px;
    font-size: 0.875rem;
    color: $white;
    .downloadIcon {
      rotate: 270deg;
      height: 15px;
      width: 15px;
      stroke: $white;
      stroke-width: 2;
    }
  }

  .moreOptionsLink {
    position: relative;
    display: flex;
    align-items: center;
    padding-left: 8px;
    cursor: pointer;
    .hamburgerIconWrapper {
      display: flex;
      align-items: center;
    }

    .hamburgerIcon {
      width: 15px;
      height: 15px;
      stroke-width: 2px;

      path {
        stroke: $white;
      }
    }

    button {
      display: none;
    }
  }

  &.expanded {
    padding-left: 8px;
    .supportLink {
      gap: 16px;
    }
    .profile {
      gap: 12px;
    }
    .centered {
      justify-content: center;
    }

    .logoutLink {
      padding-left: 0px;
      gap: 8px;
    }

    .moreOptionsLink {
      padding-left: 0;

      &:hover {
        text-decoration: underline;
      }

      button {
        cursor: pointer;
        display: block;
        color: $white;
        border: none;
        background: none;
        font-size: 0.875rem;
      }

      .hamburgerIcon {
        width: 15px;
        height: 15px;
      }
    }
  }

  @media (max-width: $mobile-width) {
    padding-left: 8px;
    .supportLink {
      gap: 16px;
    }
    .profile {
      gap: 12px;
    }
    .centered {
      justify-content: center;
    }

    .logoutLink {
      padding-left: 0px;
      gap: 8px;
    }

    .moreOptionsLink {
      padding-left: 0;

      &:hover {
        text-decoration: underline;
      }

      button {
        cursor: pointer;
        display: block;
        color: $white;
        border: none;
        background: none;
        font-size: 0.875rem;
      }

      .hamburgerIcon {
        width: 15px;
        height: 15px;
      }
    }
  }
}
