@import '@crystal-eyes/constants';@import '@crystal-eyes/constants';

.participantContainer {
  display: flex;
  flex-direction: column;
  width: 100%;

  .gridItemContent {
    height: 100%;
    width: 100%;
    box-sizing: border-box;
    padding: 1em;
    overflow-y: auto;
    &.uncontained {
      overflow-y: visible;
    }
  }

  .gridItem {
    background: $white;
    height: 100%;
    width: 100%;
    border-radius: 4px;
  }
}
