@import '@crystal-eyes/constants.scss';

.container {
  margin: 0 8px;
  flex: 1;
  position: relative;

  &:first-child {
    margin-left: 0;
  }

  &:last-child {
    margin-right: 0;
  }

  &.flat {
    margin: 0;
  }

  @media (max-width: 500px) {
    width: 100%;
    margin-bottom: 20px;
  }

  &.noContainerMargin {
    margin: 0;
  }

  .input {
    width: 100%;
    display: block;
    margin-bottom: 8px;
    background-color: $white;
    border-radius: 3px;
    padding: 11px 8px;
    min-width: 160px;
    font-family: var(--font-lato);
    font-weight: 400;
    letter-spacing: 0.5px;
    font-size: 14px;
    border: 0.5px solid $gray-outline;

    &.no-margin {
      margin-bottom: 0;
    }
    &.loading {
      padding-left: 42px;
    }

    &.flat {
      height: 68px;
      border-radius: 0;
      margin-bottom: 0;
      transition: all 0.2s;
      padding: 4px 24px;
      border: 1px solid #fff;
      border-bottom: 1px solid $material-08;
      &.borderLeft {
        border-left: 1px solid $material-08;
      }
      &:focus {
        z-index: 1000;
        outline: none;
        border: 1px solid $blue;
      }
      &.hasError {
        background: rgba($red, 0.1);
      }
    }

    &[readonly] {
      cursor: not-allowed;
    }

    &.hasError {
      border-color: $disc-D;
    }
  }

  input[data-property='website'] {
    display: none;
  }

  .noMargin {
    margin: 0;
  }
}

.loader {
  position: absolute;
  top: 10px;
  left: 14px;
}

.error-tooltip {
  position: absolute;
  top: 26px;
  right: 24px;
}

.error {
  color: $red;
  font-size: 14px;
}

.error-popup {
  position: absolute;
  top: calc(100% - 10px);
  width: 180px;
  background: #fff;
  right: 16px;
  box-shadow: 0 3px 9px 1px rgba(0, 0, 0, 0.1);
  z-index: 100;
  padding: 6px 14px;
  border-radius: 4px;
  color: rgba(0, 0, 0, 0.8);
  font-size: 14px;
  text-align: center;
  border: 1px solid $red;
  animation: fadeIn 0.2s 1 cubic-bezier(0, 0.66, 0.32, 1.34);

  &:before {
    content: '';
    position: absolute;
    bottom: 100%;
    left: 50%;
    margin-left: -10px;
    width: 0;
    height: 0;
    z-index: -1;
    border-bottom: solid 10px $red;
    border-left: solid 10px transparent;
    border-right: solid 10px transparent;
  }
}

.bottomError {
  color: $red;
  font-size: 14px;
  font-style: italic;
  font-family: var(--font-lato);
  font-weight: 300;
}

.input-container {
  position: relative;

  .caret-btn {
    background: transparent;
    position: absolute;
    border: none;
    height: fit-content;
    top: 7px;
    bottom: 50%;
    right: 1px;
    cursor: pointer;
  }

  .down-caret {
    border-left: 0.5px solid $gray-background;
    padding: 9px;
  }
}
