@import '@crystal-eyes/constants.scss';

.navItem {
  display: flex;
  align-items: center;
  gap: 16px;
  cursor: pointer;
  color: $white;
  padding: 8px;
  border-radius: 4px 0px 0px 4px;
  font-size: 0.875rem;
  line-height: 140%;
  font-weight: 400;

  &:hover,
  &.selected,
  &.selected:hover {
    background: $background-blue;
    color: $white;
    text-decoration: none;
  }

  svg {
    height: 20px;
    width: 20px;
    stroke: $white;
    stroke-width: 1px;
  }
}
