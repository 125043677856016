@import '@crystal-eyes/constants.scss';

.displayArea {
  :global svg.with-click-handler {

    :global polygon {
      cursor: pointer;
    }
  }
}

.displayArea {
  width: 100%;
  height: 100%;
  position: relative;

  & > svg {
    width: 100%;
    height: 100%;
  }

  :global .hover-info {
    position: absolute;
    height: 85px;
    width: 300px;
    top: -85px;
    border: 1px solid #e0e0e0;

    svg {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      border-radius: 3px;

      .background {
        fill: white;
      }

      .title {
        font-weight: 300;
      }
    }
  }

  :global .polygon-fill polygon {
    transition: all .2s;
  }

  :global .fill-white {
    fill:#fff;

    &.DI.filled { fill: $disc-DI-light; }
    &.Di.filled { fill: $disc-Di-light; }
    &.D.filled { fill: $disc-D-light; }
    &.Dc.filled { fill: $disc-Dc-light; }
    &.CD.filled { fill: $disc-CD-light; }
    &.Cd.filled { fill: $disc-Cd-light; }
    &.C.filled { fill: $disc-C-light; }
    &.Cs.filled { fill: $disc-Cs-light; }
    &.SC.filled { fill: $disc-SC-light; }
    &.Sc.filled { fill: $disc-Sc-light; }
    &.S.filled { fill: $disc-S-light; }
    &.Si.filled { fill: $disc-Si-light; }
    &.IS.filled { fill: $disc-IS-light; }
    &.Is.filled { fill: $disc-Is-light; }
    &.I.filled { fill: $disc-I-light; }
    &.Id.filled { fill: $disc-Id-light; }
  }

  :global .fill-gray {
    fill:#e0e0e0;
  }

  :global .stroke-none {
    stroke: none;
  }

  :global .stroke-gray {
    stroke:#e0e0e0;
    stroke-miterlimit:10;
  }

  :global .fill-black{
    fill:#000;
  }
}