@import '@crystal-eyes/constants.scss';

.container {
  margin-left: 8px;
  display: flex;
  align-items: center;

  &.smallMargin {
    margin-left: 4px;
  }

  &.inline {
    display: inline-flex;
  }

  @media print {
    display: none;
  }
}

.icon {
  width: 16px;
  height: 16px;
  opacity: 0.38;
  transition: opacity 150ms ease-in-out;
  &:hover {
    opacity: 0.87;

    &.fixedPosition {
      opacity: 1;
    }

    .fixedPosition {
      opacity: 1;
      transform: scale(1);
      z-index: 10000;
      opacity: 1;
    }
  }
  &.light {
    opacity: 0.16;
  }
}

.tip {
  display: block;
  pointer-events: none;
  font-family: var(--font-lato);
  font-weight: 400;
  justify-content: center;
  align-items: center;
  padding: 6px 14px;
  border-radius: 4px;
  background-color: #fff;
  box-shadow: 0 3px 9px 1px rgba(0, 0, 0, 0.1);
  color: $material-87;
  font-size: 16px;
  text-align: center;
  text-decoration: none;
  width: 300px;
  line-height: 1.6em;
  white-space: normal;
  text-transform: none;

  &.noPadding {
    padding: 0;
  }

  &.fixedPosition {
    transform-origin: top;
    position: fixed;
    left: calc(50% - 300px) !important;
    top: calc(50% - 50px) !important;
    max-width: 100vw;
    width: 600px;
    box-shadow: 0 3px 12px 1px rgba(0, 0, 0, 0.12);
    opacity: 0;
    pointer-events: none;
    z-index: -1;
    transform: scale(0.8);
    background-color: $white;

    @media (max-width: 600px) {
      left: 0;
    }
  }

  &.smallText {
    font-size: 14px;
  }
}
