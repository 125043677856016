@import '@crystal-eyes/helpers.scss';

.editProfileModal {
  width: 100vw;
  max-width: 900px;
  background-color: white;
  min-height: 450px;

  .icon {
    @include svg-bold();
  }

  :global(.card) {
    height: 100%;
    overflow-y: auto;
    box-sizing: border-box;
  }

  :global(.section-card-heading) {
    font-size: 1rem;
  }
  :global(.profile-settings) {
    display: grid;
    grid-template-columns: 2fr 2fr 1fr;
    column-gap: 20px;
    margin: 20px;

    & > :global(.setting) {
      label {
        display: flex;
        flex-direction: column;
        row-gap: 10px;
      }

      input,
      select {
        height: 2.5rem;
        font-size: 1rem;
        line-height: 2rem;
        border: 1px solid #cecece;
        border-radius: 5px;
        padding: 10px;
        box-sizing: border-box;
      }
    }
  }

  :global(.type-and-intensity) {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin: 20px 20px;
    row-gap: 20px;

    & > section {
      display: flex;
      justify-content: center;
      align-items: center;
      flex-direction: column;
      width: 100%;
    }

    :global(.disc-wheel) {
      width: 300px;
      height: 300px;
    }

    :global(.intensity) {
      :global(.intensity-value) {
        font-weight: bold;
      }

      :global(.explanations) {
        display: flex;
        justify-content: space-between;
        width: 100%;

        :global(.explanation) {
          display: flex;
          align-items: center;
          font-size: 0.8rem;
          column-gap: 5px;
          color: #7e7e7e;
        }
      }

      input[type='range'] {
        appearance: none;
        width: 100%;
        height: 5px;
        border-radius: 5px;
        background: #d3d3d3;
        outline: none;
        opacity: 0.7;
        -webkit-transition: 0.2s;
        transition: opacity 0.2s;
        margin: 20px 20px;

        &::-webkit-slider-thumb {
          -webkit-appearance: none;
          appearance: none;
          width: 25px;
          height: 25px;
          border-radius: 50%;
          background: #04aa6d;
          cursor: pointer;
        }

        &::-moz-range-thumb {
          width: 25px;
          height: 25px;
          border-radius: 50%;
          background: #04aa6d;
          cursor: pointer;
        }
      }
    }
  }

  :global(.buttons) {
    display: flex;
    margin-top: 20px;
    column-gap: 10px;
  }
}
