.container {
  display: flex;
  column-gap: 2em;
  align-items: center;
  justify-content: center;
  padding-top: 1em;
}

.chartWrapper {
  height: 100%;
  max-width: 300px;
  display: flex;
}

.tagWrapoer {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0.5em 1em;
}
