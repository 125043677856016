@import '@crystal-eyes/constants.scss';

.navSection {
  display: flex;
  flex-direction: column;
  white-space: nowrap;
  gap: 1px;

  .navTitle {
    display: flex;
    align-items: center;
    gap: 36px;
    cursor: pointer;
    color: $white;
    font-size: 0.875rem;
    padding: 8px;
    line-height: 140%;
    font-weight: 400;
    border-radius: 4px 0px 0px 4px;

    svg {
      height: 15px;
      width: 15px;
      stroke: $white;
      stroke-width: 2px;
      path {
        stroke: $white;
      }
      g {
        * {
          stroke: white;
        }
      }
    }

    &.external {
      color: $blue;

      svg {
        stroke: $blue;
      }
    }

    &:hover {
      background: $background-blue;

      svg {
        stroke: $white;

        path {
          stroke: $white;
        }
      }
    }

    &.noSubNav {
      &.selected {
        background: $background-blue;
        svg {
          stroke: $white;
          path {
            stroke: $white;
          }
        }
      }
    }
  }
  .navLinks {
    display: none;
    flex-direction: column;
    padding-left: 56px;
    opacity: 0;
    &.show {
      display: flex;
      gap: 1px;
      height: auto;
      opacity: 1;
    }
  }

  &.expanded {
    .navTitle {
      gap: 16px;
    }
    .navLinks {
      padding-left: 40px;
    }
  }

  @media (max-width: $mobile-width) {
    .navTitle {
      gap: 16px;
    }
    .navLinks {
      padding-left: 50px;
    }
  }
}
