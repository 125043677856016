@import '@crystal-eyes/constants.scss';

.identityCircle {
  display: inline;
  position: relative;

  image {
    clip-path: inset(0 0 0 0 round 50%);
    cursor: pointer;
  }

  circle {
    cursor: pointer;
  }

  text {
    font-size: 0.5rem;
    pointer-events: none;
    cursor: pointer;
  }
}

.loadingCircle {
  border-radius: 50%;
  animation: 1.8s linear infinite;
  animation-fill-mode: forwards;
  background: linear-gradient(to right, #f8f8f8 8%, #dfdfdf 38%, #f8f8f8 54%);
  background-size: 1000px 640px;
  position: relative;
}

.star {
  position: absolute;
  top: -0.2rem;
  right: 0;
  width: 20px;
  height: 20px;
}

.textPill {
  width: fit-content;
  text-align: center;
  background: $blue;
  padding: 6px 12px;
  border-radius: 100px;
  pointer-events: none;
  margin-top: -12px;
  z-index: 1;
  white-space: nowrap;
  font-size: 0.875rem;
}
