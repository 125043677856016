@import '@crystal-eyes/constants.scss';

.body {
  font-family: var(--font-lato);
  font-weight: normal;
  -webkit-font-smoothing: antialiased;
  min-width: max-content;

  &.enterprise {
    background-color: $bg-gray-2;
    background-image: none;
  }

  input,
  select {
    font-size: 1rem;
    line-height: 1.4rem;
    border: 1px solid #cecece;
    border-radius: 5px;
    padding: 10px;
    box-sizing: border-box;
  }

  button {
    font-family: var(--font-lato);
  }

  input {
    font-family: var(--font-lato);
    font-size: 0.875rem;

    &::placeholder {
      font-family: var(--font-lato);
    }
  }

  label {
    font-family: var(--font-lato);
    font-size: 1rem;
    line-height: 1.4rem;
    font-weight: 700;
    color: $gray-text;
  }
}
