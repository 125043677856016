@import '@crystal-eyes/constants.scss';

.photoIcon {
  width: 2rem;
  height: 2rem;
  object-fit: cover;
  border-radius: 1rem;
  margin: 0 2px;
}

.initialsIcon {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;

  width: 2rem;
  height: 2rem;
  border-radius: 1rem;
  margin: 0 2px;
  color: white;

  &.none {
    background-color: #030303;
  }
  &.Dc {
    background-color: $disc-Dc;
  }
  &.D {
    background-color: $disc-D;
  }
  &.Di {
    background-color: $disc-Di;
  }
  &.DI {
    background-color: $disc-DI;
  }
  &.Id {
    background-color: $disc-Id;
  }
  &.I {
    background-color: $disc-I;
  }
  &.Is {
    background-color: $disc-Is;
  }
  &.IS {
    background-color: $disc-IS;
  }
  &.Si {
    background-color: $disc-Si;
  }
  &.S {
    background-color: $disc-S;
  }
  &.Sc {
    background-color: $disc-Sc;
  }
  &.SC {
    background-color: $disc-SC;
  }
  &.Cs {
    background-color: $disc-Cs;
  }
  &.C {
    background-color: $disc-C;
  }
  &.Cd {
    background-color: $disc-Cd;
  }
  &.CD {
    background-color: $disc-CD;
  }
}
