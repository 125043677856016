@import '@crystal-eyes/constants.scss';

.container {
  position: fixed;
  left: 0;
  top: 0;
  width: 100vw;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 1;
  transition: all 0.3s;
  z-index: 100000;
  overflow-y: hidden;
  &.fadeOut {
    pointer-events: none;
    animation: fadeOut 0.1s 1 linear;
    opacity: 0;
    &.slowAnimation {
      animation: fadeOut 0.3s 1 linear;
    }
  }
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.background {
  position: fixed;
  left: 0;
  top: 0;
  width: 100vw;
  min-height: 100vh;
  background: rgba(0, 0, 0, 0.4);
  transition: all 0.3s;
  animation: fadeIn 0.1s 1 linear;
  &.slowAnimation {
    animation: fadeIn 0.3s 1 linear;
  }
  &.dark {
    background-color: rgba($dark-blue, 0.9);
    &:after {
      content: '';
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background: linear-gradient(
        45deg,
        transparent 0%,
        rgba($dark-blue, 0.7) 100%
      );
    }
  }
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.content {
  display: block;
  position: relative;
  padding: 26px 34px;
  background: #fff;
  border-radius: 4px;
  max-width: 100%;
  max-height: 100vh;
  &.grey {
    background: #f5f5f5;
  }
  &.lightGrey {
    background: #fcfcfc;
  }
  &.noPadding {
    padding: 0;
  }
  &.overflow {
    max-height: 80vh;
    overflow-x: hidden;
    overflow-y: auto;
  }
  &.overflowFull {
    max-height: 95vh;
    overflow-x: hidden;
    overflow-y: auto;
  }
}

.back-icon {
  position: absolute;
  right: 16px;
  top: 16px;
  opacity: 1;
  cursor: pointer;
  z-index: 10000;
  width: 18px;
  height: 18px;
  padding: 2px;
  transition: all 0.2s;
  opacity: 0.34;
  &:hover {
    opacity: 0.54;
    transform: scale(1.1);
  }
  &.lightClose {
    filter: brightness(100);
  }
}

.noPrint {
  @media print {
    display: none;
  }
}
