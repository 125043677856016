@import '@crystal-eyes/constants.scss';

.sideNavBar {
  width: $side-nav-mini-width;
  max-width: $side-nav-width;
  height: 100vh;
  background: $crystal-midnight-blue;
  position: sticky;
  top: 0;
  z-index: 100;
  overflow-y: auto;
  transition: width 0.1s ease;

  &.miniView {
    position: fixed;
  }

  .mobileHamburger {
    display: none;
    position: absolute;
    cursor: pointer;
    padding: 8px;
    color: $blue;
    font-size: 32px;
    svg {
      width: $mobile-more-size;
      height: $mobile-more-size;
    }

    @media (max-width: $main-content-width) {
      display: block;
    }
  }

  .navigationContainer {
    padding: 24px 0px 24px 18px;
    height: calc(100% - 48px);
    width: 300px;
    display: flex;
    gap: 28px;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;

    @media (max-width: $main-content-width) {
      display: none;
      &.mobileOpen {
        display: flex;
      }
    }
  }

  .navHeader {
    padding: 0 0 24px 8px;
  }

  .navBody {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 100%;
    width: 100%;
  }

  .navSubContainer {
    display: flex;
    flex-direction: column;
    gap: 16px;
  }
  .mainNavigation {
    display: flex;
    flex-direction: column;
    gap: 1px;
  }

  &.expanded {
    width: $side-nav-width;

    .navigationContainer {
      width: calc(300px - 18px);
    }
  }

  @media (max-width: $main-content-width) {
    width: 50px;
    position: absolute;
    height: 48px;
    background: transparent;
    overflow-y: unset;
    &.mobileOpen {
      width: 300px;
      height: 100vh;
      background: $crystal-midnight-blue;

      .mobileHamburger {
        right: 8px;
      }
    }
  }
}
