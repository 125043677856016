@import '@crystal-eyes/constants.scss';
@import '@crystal-eyes/helpers.scss';

.sortArrow {
    transform: rotate(90deg);
    &.ASC {
      margin-left: 5px;
      transform: rotate(-90deg);
    }
    &.active {
      color: $action-blue;
    }
  }
.container {
  width: 18px;
  height: 18px;
}